<template>
  <div class="Information-detail-box">
    <headert></headert>
    <el-row class="content">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="top-banner">
          <img src="../../assets/image/info/banner.png" alt="">
        </div>
      </el-col>
    </el-row>
    <div>
      <navMenu :activeIndex='menuActiveIndex' :menu='menu' :breadcrumb='breadcrumb' />
      <el-row class=" WJgroupIntroduce-Box" type="flex" justify="center" align='center'>
        <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">

          <h1 class="detailTitle">{{news.title}}</h1>
          <h6 class="detailMsg"><span>{{$t('news.creatTime')}} {{news.add_time_format ?news.add_time_format.Ymd:'' }}</span><span>{{$t('news.source')}}</span></h6>
        </el-col>
      </el-row>
      <el-row class=" detailContent" type="flex" justify="center" align='center'>
        <p class="dafter"></p>
        <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
          <div v-html="news.content">

          </div>
        </el-col>
      </el-row>
      <el-row class=" " type="flex" justify="center" align='center'>

        <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
          <div class="otherDetail">
            <div @click="tonextDetail(preDetail)">
              <p>{{$t('news.prev')}}</p>
                <h6 v-if="preDetail">{{preDetail.title}}</h6>
              <h6 v-else>{{$t('news.prevTips')}}</h6>
            </div>
            <div  @click="tonextDetail(nextDetail)">
              <p>{{$t('news.next')}}</p>
              <h6 v-if="nextDetail">{{nextDetail.title}}</h6>
              <h6 v-else>{{$t('news.nextTips')}}</h6>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <footerb></footerb>

  </div>
</template>

<script>
  export default {
    name: "WJgroupIntroduce",
    components: {},
    data() {
      return {
        params: {},
        PageTitle: '集团动态',
        menuActiveIndex: '/Information/1',
        menu: {
          name: this.$t('menu.news'),
          path: '/Information/1',
          list: [{
            name: this.$t('menu.trends'),
            path: '/Information/1',
          }, {
            name:this.$t('menu.information'),
            path: '/Information/2',
          }, ]
        },
        breadcrumb: [{
          name:this.$t('menu.news'),
          path: '/Information/1',
        }, {
          name: this.$t('menu.trends'),
          path: '/Information/1',
        },{
          name: this.$t('menu.detail'),
          path: '',
        }],

        id:null,
        news:{},
        nextDetail:{},
        preDetail:{}
      };
    },


    watch: {
      $route() {
        // 路径发生变化，$route会重新赋值，监控了这个属性，会执行这个函数
        this.getroute()
      this.getData();
      }
    },
    created() {
       this.id =this.$route.query.id;
      this.getroute()
      this.getData();

    },
    methods: {
      tonextDetail(item){
        this.$router.push({path:'InformationDetail',query:{type:this.$route.query.type,id:item.aid}})
      },
      getData(){
         this.id =this.$route.query.id;
        let api='?m=api&c=v1.Api&a=index&_ajax=1';
        this.$request.post(api,{
          apiArcview_1:`ekey=1&aid=${this.id}&addfields=title_en,seo_description_en,content_en,title_en,content`,//获取文章详情
          apiPrenext_1:`ekey=1&aid=${this.id}&addfields=title_en,seo_description_en,content_en,content`
        }).then(res=>{
          if(res.apiArcview[1].data){
            this.news = this.$utils.translate(res.apiArcview[1].data);
            if(res.apiPrenext[1].nextDetail && res.apiPrenext[1].nextDetail.data){
               this.nextDetail=this.$utils.translate(res.apiPrenext[1].nextDetail.data);
               console.log(res.apiPrenext[1].nextDetail)
            }else{
              this.nextDetail=null
            }
            if(res.apiPrenext[1].preDetail && res.apiPrenext[1].preDetail.data){
              this.preDetail= this.$utils.translate(res.apiPrenext[1].preDetail.data);
            }else{
              this.preDetail=null
            }


          }
        })
      },
      getroute() {
        this.nowPath = this.$route.fullPath;
        this.type = this.$route.query.type;
        // this.type=this.nowPath.substring((this.nowPath.lastIndexOf('\/')+1),this.nowPath.length)
        if (this.type == 1) {
          this.menuActiveIndex = '/Information/1';
          this.breadcrumb[1] = {
            name:this.$t('menu.trends'),
            path: '/Information/1',
          }
          this.PageTitle =this.$t('menu.trends')
        } else if (this.type == 2) {
          this.menuActiveIndex = '/Information/2';
          this.breadcrumb[1] = {
            name: this.$t('menu.information'),
            path: '/Information/2',
          }
          this.PageTitle = this.$t('menu.information')
        } else if (this.type == 3) {
          this.menuActiveIndex = '/Information/3';
          this.breadcrumb[1] = {
            name:this.$t('menu.Media'),
            path: '/Information/3',
          }
          this.PageTitle = this.$t('menu.Media')
        }else if (this.type == 139) {
          this.PageTitle=this.$t('school.classShow'),
           this.menuActiveIndex='/personnelCadreSchool';
          this.menu= {
            name: this.$t('menu.recruit'),
            path: '/personneltalentConcept',
            list: [{
              name: this.$t('menu.talented'),
              path: '/personneltalentConcept',
            }, {
              name: this.$t('menu.school'),
              path: '/personnelCadreSchool',
            }, {
              name: this.$t('menu.joinUs'),
              path: '/personnelJoinUs',
            }, ]
          };
          this.breadcrumb= [{
            name: this.$t('menu.recruit'),
            path: '/personneltalentConcept',
          }, {
            name: this.$t('menu.school'),
            path: '/personnelCadreSchool',
          }, {
            name: this.$t('school.classShow'),
            path: '/moreSchool',
          }];
        }
      },
      handleCurrentChange() {

      },
      choseYears(index) {
        this.yearIndex = index
      },
      onSwiper(swiper) {
        console.log(swiper);
      },
      onSlideChange() {
        console.log("slide change");
      }
    }
  };
</script>

<style lang="less">
  .margin-lf {
    margin-left: 30px;
    margin-right: 30px;
  }

  .news-TopRow {
    cursor: pointer;
    padding: 0px 0 60px;
  }

  .news-TopRow:hover .news-TopRow-time p {
    // background: #AF8E68 !important;
    color: #AF8E68 !important;

  }

  .news-TopRow:hover .news-TopRow-title {
    color: #AF8E68 !important;

  }

  .news-TopRow-img {
    width: 100%;
    height: 280px;
    margin-bottom: 20px;
  }

  .news-TopRow-img img {
    width: 100%;
    height: 100%;
  }

  .news-TopRow-time {}

  .news-TopRow-time h5 {
    line-height: 38px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
  }

  .news-TopRow-time img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }

  .news-TopRow-time p {
    // width: 94px;
    // height: 40px;
    // background: #CCCCCC;
    border-radius: 2px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ccc;
    line-height: 40px;
    text-align: center;
  }



  .news-TopRow-title {
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    line-height: 57px;
    height: 57px;
    width: 100%;
  }

  .detailTitle {
    margin-top: 47px;
    font-size: 24px;
    // font-family: OPPOSans-Medium, OPPOSans;
    font-weight: 500;
    color: #1F2D39;
    line-height: 32px;
    text-align: center;
  }

  .detailMsg {

    font-size: 16px;
    // font-family: OPPOSans-Regular, OPPOSans;
    font-weight: 400;
    color: #797979;
    line-height: 19px;
    text-align: center;
    margin-top: 20px;

  }

  .detailMsg span {
    padding: 0 10px;
  }

  .detailContent {
    border-top: 1px solid #D8D8D8;
    border-bottom: 1px solid #D8D8D8;
    padding: 80px 0;
    margin: 45px 0 15px;
    position: relative;
  }

  .detailContent .dafter {

    position: absolute;
    top: -2px;
    left: 50%;
    margin-left: -36px;
    width: 72px;
    height: 4px;
    background: #A4825F;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
  }

  .detailContent P {
    text-indent: 2em;
    font-size: 16px;
    font-weight: 400;
    color: #1F2D39;
    line-height: 35px;
    margin-bottom: 30px;
  }
  .detailContent span{
    max-width: 100%;
  }
  .detailContent img{
    max-width: 95%;
  }
  .otherDetail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 76px;
    padding-top: 40px;
  }

  .otherDetail div {
    cursor: pointer;
    width: 40%;
    padding: 14px 24px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    border: 1px solid #D8D8D8;
  }

  .otherDetail div:hover p {
    color: #af8e68;
  }

  .otherDetail div:hover h6 {
    color: #af8e68;
  }

  .otherDetail div p {

    font-size: 16px;
    // font-family: OPPOSans-Regular, OPPOSans;
    font-weight: 400;
    color: #979797;
    line-height: 19px;
  }

  .otherDetail div h6 {
    margin-top: 16px;
    font-size: 18px;
    // font-family: OPPOSans-Medium, OPPOSans;
    font-weight: 500;
    color: #666666;
    line-height: 21px;
  }
  @media screen and (min-width:320px) and (max-width:767px) {
    .Information-detail-box{
    .detailTitle{
      font-size: 39px;
      line-height: 60px;
    }
    .detailMsg{
      span{
        font-size: 34px;
      }
    }

    .otherDetail {
        p{
          font-size:34px;
          line-height: 44px;
        }
      h6{
        font-size:34px;
        line-height: 52px;
        height: 52px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;/*文本不进行换行*/
      }
    }
    }
  }
</style>
